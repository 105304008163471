// ---- Imported Styles ----
// CoreUI
@import '~@coreui/coreui/dist/css/coreui.css';
// CoreUI Icons Set
@import "~@coreui/icons/css/all.css";
// Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";

//@import '@fortawesome/fontawesome-svg-core/styles.css';
@import 'font-awesome/css/font-awesome.min.css';
@import '~react-notifications/lib/notifications.css';
@import "react-datepicker/dist/react-datepicker.css";


:root {
  --cui-body-font-size: .9rem;
  --cui-body-font-weight: 300;
  --lime-main-color: rgb(2, 140, 127);
  --cui-nav-link-color: var(--lime-main-color);
  --cui-nav-link-hover-color: rgba(2, 140, 127, 0.7);
  --cui-accordion-button-active-bg: #e5f6f5;
  --cui-accordion-button-active-color: var(--lime-main-color);
}
.row {
  //--cui-gutter-x: .8rem;
}

.btn-primary {
  --cui-btn-bg: var(--lime-main-color);
  --cui-btn-border-color: var(--lime-main-color);
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #1ec0bb;
  --cui-btn-hover-border-color: #14b6b1;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #28cac5;
  --cui-btn-active-border-color: #14b6b1;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #aec7c6;
  --cui-btn-disabled-border-color: #aec7c6;
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-shadow: rgba(0, 167, 157, 0.5);
}

.btn-outline-secondary {
  --cui-btn-hover-bg: var(--cui-btn-bg, transparent);
  --cui-btn-hover-border-color: var(--cui-btn-border-color, transparent);
  --cui-btn-hover-color: #6b737f;
}

.btn-back {
  --cui-btn-bg: #cc3c8c;
  --cui-btn-border-color: #c53f8a;
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #e555a5;
  --cui-btn-hover-border-color: #da529e;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #e363aa;
  --cui-btn-active-border-color: #d7599f;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #ef98c9;
  --cui-btn-disabled-border-color: #e088ba;
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-shadow: rgb(191 81 143);
}

@mixin card-style {
  border-radius: 5px;
  border: #cbdad9 solid 1px;
  box-shadow: 0 3px 8px rgb(0 0 0 / 20%);
}

html, body {
  height: 100%;
  font-size: .9rem;
  font-weight: 500;
}

h1, h2, h3, h4, h5 {
  text-transform: uppercase;
  font-weight: 300;
  color: #888;
}

h1 {
  margin-bottom: 2rem;
  font-size: 2.2rem;
}

h2 {
  font-size: 1.9rem;
}

thead {
  th {
    &.sortable {
      cursor: pointer;

      div.sorting {
        padding-top: 12px;
        padding-left: 5px;
        position: absolute;
        display: inline-block;
        color: #42bdb3;

        &:before {
          position: absolute;
          bottom: -7px;
          content: "\f176"; //"\f0de"
          font-weight: 200;
          font-size: 1rem;
          font-family: 'FontAwesome';
          opacity: 0;
        }

        &:after {
          position: absolute;
          bottom: -7px;
          content: "\f175"; //"\f0dd"
          font-weight: 200;
          font-size: 1rem;
          font-family: 'FontAwesome';
          opacity: 0;
        }
      }

      div.sorting.ASC:after {
        opacity: 1
      }

      div.sorting.DESC:before {
        opacity: 1
      }
    }
  }
}

#root {
  background-color: rgb(250,250,250);
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-page {
  background-color: lightgray;
  & .alert-danger {
    background-color: transparent;
    border: none;
    color: red;
    font-size: .8rem;
    display: inline-block;
    max-width: 300px;
  }
}

.page {
  min-height: 100vh;
  justify-content: center;
}

.error-page {
  margin-top: -20vh;
}

.navbar {
  width: 100%;
  background-color: lightgray;
  font-size: .9rem;
  text-transform: uppercase;
  //box-shadow: 0 3px 3px rgb(0 0 0 / 20%);
  border: none;
}
.navbar-collapse {
  flex-grow: unset;
}

.dropdown-menu li {
  font-size: .8rem;
  cursor: pointer;
}

.navbar-brand .logo {
  height: 60px;
}

.navbar-expand-md .navbar-nav .nav-link {
  padding-right: 2rem;
  padding-left: 2rem;

  @media screen and (max-width: 990px) {
    font-size: .9em;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.navbar-nav .nav-link {
  color: var(--cui-header-active-color, rgba(44, 56, 74, 0.65));
  &.active {
    text-shadow: 1px 1px 3px rgb(0 0 0 / 40%);
  }
}

.nav-tabs .nav-link {
  text-transform: uppercase;
  font-size: 1rem;
  &.active {
    font-weight: 400;
  }
}

.breadcrumb .nav-link {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}

.breadcrumb .breadcrumb-item:first-of-type .nav-link {
  padding-left: 0;
}

.tab-content {
  margin-top: 2rem;
}

.app-body {
  width: 100%;
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.footer-brand {
  width: 100%;
  font-size: .8rem;
}

.modal-header {
 background-color: var(--cui-modal-header-border-color, #d8dbe0);
}

.modal-title {
  color: inherit;
  text-transform: none;
  font-weight: 400;
}

.modal-footer {
  border-top: none;
}

.btn {
  //font-weight: 300;
  //font-size: 1rem;
  //padding: 0.3rem 0.8rem;
  & .btn-addon {
    margin-left: .6rem;
  }
}

.cancel.btn {
  color: #888888;
  margin-right: 1rem;
}

form.row {
  margin-top: 2rem;
}

.form-check {
  .form-check-input {
    float: none;
  }
  .form-check-label {
    margin-left: .5rem;
  }
}

.form-control, .form-select {
  font-size: .9rem;
  //line-height: 1.1;
  padding-left: .5rem;
  padding-right: .5rem;
}

.number-control, .money-control {
  text-align: right;
}

.form-title {
  text-transform: uppercase;
  font-size: 2.4rem;
  color: #888;
  font-weight: 600;
  margin-bottom: 2rem;
}

.form-label {
  font-weight: 500;
  font-size: .9rem;
  @media screen and (min-width: 576px) {
    text-align: right;
  }
}

input[type="number"] {
  text-align: right;
}

.filter-form  {
  border-bottom: 1px solid #bbb;
  margin-bottom: 2rem;
  &.row {
    margin-top: 0;
  }
  & .form-label {
    font-weight: 500;
    font-size: .9rem;
    width: 100%;
  }
}

.rate-type .form-label {
  font-weight: 500;
  font-size: .9rem;
}

.inline-checkboxes {
  display: inline-block;
  margin-top: 10px;
  margin-left: 30px;
}

form {
  .row-cols-md-2 .col:not(:last-child) {
    @media screen and (min-width: 768px) {
      border-right: 1px solid #cccccc;
    }
  }
  .row-cols-lg-3 .col:not(:last-child), .row-cols-lg-2 .col:not(:last-child) {
    @media screen and (min-width: 992px) {
      border-right: 1px solid #cccccc;
    }
  }
  .row-cols-xl-3 .col:not(:last-child) { //}, .row-cols-xl-2 .col:not(:last-child) {
    @media screen and (min-width: 1200px) {
      border-right: 1px solid #cccccc;
    }
  }
}

.form-group-separator {
  border-bottom: 1px solid #cccccc;
}

.invalid-feedback.force-show {
  display: block;
}

.image-upload {
  @include card-style;
  width: 100%;
  min-height: 260px;
}
div.image-upload {
  height: 100%;
  background-color: white;
  color: #aaa;
  display : flex;
  flex-direction: column;
  align-items : center;
  justify-content : center;
  i.icon-picture {
    font-size: 5rem;
    vertical-align: middle!important;
  }
  p {
    font-size: 1.2rem;
    text-transform: uppercase;
  }
}

.photo-image-upload-wrapper {
  text-align: right;
  @media screen and (min-width: 992px) {
    margin: -3rem 1rem -9rem -1rem;
  }
}

.avatar-image-upload-wrapper {
  height: 100%;
}

.photo-upload-button {
  position: relative;
  bottom: 3rem;
  right: 1rem;
  & i {
    color: #00a79d;
  }
}

.avatar-upload-button {
  margin-top: 1rem;
}

.login-card {
  padding: 2em;
  @include card-style
}
.fc, form:not(.login-form, .filter-form, .artist-agent-form) {
  padding: 2em;
  background-color: #e5f6f5;
  @include card-style
}

.fc {
  @media screen and (min-width: 992px) {
    margin: 0 5em;
  }

  & .fc-col-header-cell {
    background-color: var(--lime-main-color);
  }

  & .fc-col-header-cell-cushion {
    color: white;
    font-weight: 300;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.2em;
  }

  & .fc-daygrid-day {
    background-color: white;
  }

  & .fc-day-other {
    background-color: #f5f5f5;
  }

  & .fc-daygrid-day-number {
    color: #333;
    text-decoration: none;
    font-size: 1.2em;
  }

  .fc-daygrid-block-event .fc-event-title {
    padding: 4px;
  }
}

.shows-list-image {
  width: 120px;
  padding: 2rem;
  text-align: center;
  & i {
    color: #cccccc;
    font-size: 6rem;
  }
}

section.relationship {
  margin-top: 4rem;

  form:not(.filter-form) .btn {
    @media screen and (min-width: 992px) {
      position: absolute;
      top: 26px;
      right: 0;
    }
  }
}

.react-datepicker {
  .react-datepicker__triangle {
    position: absolute;
    left: 50px !important;
    transform: none !important;
    z-index: 1;
  }

  .react-datepicker__day--outside-month {
    opacity: 30%;
  }
}

.show-card {
  color: white;
  background-color: var(--lime-main-color);
  font-size: 1.1rem;
  margin-top: 2rem;

  .card-title {
    color: white;
    font-size: 1.6rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #5bbebb;
    min-height: 5rem;
  }

  .card-body {
    padding: 2rem;
  }

  .time-range {
    margin-bottom: 1rem;
  }

  .bookings {
    &:first-child {
      margin-left: 3rem;
    }
    .fa {
      font-size: 1.4rem;
      line-height: 1.4rem;
    }
    .number {
      margin-left: .6rem;
    }
  }
}

.page-link {
  cursor: pointer;
}

.align-center {
  text-align: center;
}

.accordion-button:focus {
  box-shadow: 0 0 0 0.25rem rgb(2 140 127 / 25%);
}

.booking-buttons {
  min-width: 90px;
}

.react-datepicker__close-icon::after {
  background-color: #e55353;
}